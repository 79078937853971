import React, { useState, useRef, useEffect } from 'react';
import CircleRating from './CircleRating';

export default function RotatingCards({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedCard, setExpandedCard] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    console.log('Data received in RotatingCards:', data);
  }, [data]);

  useEffect(() => {
    setSortedData(data.slice(1)); // Exclude the first item (prospective mark)
  }, [data]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        e.preventDefault();
        if (e.deltaY > 0) {
          nextCard();
        } else {
          prevCard();
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, [sortedData]); // Add sortedData as a dependency

  const nextCard = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, sortedData.length - 1));
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToTop = () => {
    setCurrentIndex(0);
  };

  const goToBottom = () => {
    setCurrentIndex(sortedData.length - 1);
  };

  // Sorting function
  const sortCards = (key, order = 'asc') => {
    const sorted = [...sortedData].sort((a, b) => {
      let valueA = a[key];
      let valueB = b[key];

      // Convert to numbers if possible
      if (!isNaN(valueA) && !isNaN(valueB)) {
        valueA = Number(valueA);
        valueB = Number(valueB);
      }

      // Handle null, undefined, or empty string values
      if (valueA == null) return order === 'asc' ? 1 : -1;
      if (valueB == null) return order === 'asc' ? -1 : 1;
      if (valueA === '') return order === 'asc' ? 1 : -1;
      if (valueB === '') return order === 'asc' ? -1 : 1;

      // Sort strings alphabetically
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return order === 'asc' 
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      // Sort numbers
      return order === 'asc' ? valueA - valueB : valueB - valueA;
    });

    setSortedData(sorted);
    setCurrentIndex(0);
  };

  const renderField = (key, value) => {
    let formattedValue;
    if (value === null) {
      formattedValue = 'NULL';
    } else if (value === "") {
      formattedValue = '(empty string)';
    } else if (value === undefined) {
      formattedValue = 'undefined';
    } else if (typeof value === 'string') {
      formattedValue = value;
    } else if (Array.isArray(value)) {
      formattedValue = value.length ? value.join(', ') : '(empty array)';
    } else if (typeof value === 'object') {
      formattedValue = JSON.stringify(value);
    } else {
      formattedValue = String(value);
    }
    return (
      <div key={key} className="mb-2">
        <div className="text-xs font text-gray-500">{key.replace(/_/g, ' ').toUpperCase()}</div>
        <div className="text-sm font-bold text-gray-800">{formattedValue}</div>
      </div>
    );
  };

  const renderRatingField = (key, value, maxValue = 1, size = 50) => {
    return (
      <div key={key} className="">
        <div className="text-xs font-semibold text-gray-500">{key.replace(/_/g, ' ').toUpperCase()}</div>
        <CircleRating value={value} size={size} />
      </div>
    );
  };

  const toggleExpandCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  if (!Array.isArray(data) || data.length === 0) {
    return <div>No valid data available to display.</div>;
  }

  const prospectiveMarkCard = data[0];

  return (
    <div className="h-full flex">
      {/* Main content area */}
      <div className="flex-grow flex flex-col overflow-hidden">
        {/* Prospective Mark Card - Fixed at the top */}
        <div className="bg-white p-6 shadow-lg border-b-2 border-blue-500">
          <h2 className="text font-semibold text-gray-700 mb-2">APPLICANT MARK</h2>
         <div className="grid grid-cols-2">
         <div className="w-max">
          <h2 className="text-xs font text-gray-500">MARK IDENTIFICATION</h2>
          <h2 className="text-5xl font-bold mb-4 text-black">
            {prospectiveMarkCard.mark_identification || "Prospective Mark"}
          </h2>

         </div>
         </div>
         <div className="grid grid-cols-4 gap-4">
            {renderField("JURISDICTIONS", prospectiveMarkCard.jurisdictions)}
            {renderField("NICE CLASSES", prospectiveMarkCard.international_codes)}
          </div>
        </div>
        {/* Rotating Cards - Scrollable container */}
        <div className="flex-grow overflow-hidden relative" ref={containerRef}>
          <div className="absolute inset-0">
            {sortedData.map((card, index) => {
              const isActive = index === currentIndex;
              const translateY = (index - currentIndex) * 100;
              const scale = isActive ? 1 : 0.85;
              const zIndex = sortedData.length - Math.abs(index - currentIndex);

              return (
                <div
                  key={index}
                  className={`absolute top-0 left-0 right-0 bg-white p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out ${
                    isActive ? 'z-10' : 'opacity-30 pointer-events-none'
                  }`}
                  style={{
                    transform: `translateY(${translateY}%) scale(${scale})`,
                    zIndex,
                    border: '2px solid #e2e8f0',
                  }}
                >
                  {/* TITLE - top left, alone */}
                  {/* GRID ONE - ID/ ratings */}
                  <div className="grid grid-cols-2 flex mb-4">
                    <div>
                      <h2 className="text-sm font-semibold text-gray-600 mb-2">S:{card.serial_number}</h2>
                      <h2 className="text-xs font text-gray-500">MARK IDENTIFICATION</h2>
                      <h2 className="text-3xl font-bold">{card.mark_identification}</h2>
                    </div>
                    <div className="grid grid-cols-3">
                    <div className="text-center">
                    {renderRatingField("MARK ID CR", card.MARK_ID_TOP_SCORE, 1, 65)}
                    </div>
                    <div className="text-center">
                    {renderRatingField("PHONETIC CR", card.MID_PH_SCORES, 1, 65)}
                    </div>
                    <div className="text-center">
                    {renderRatingField("G&S CR", card.MID_PH_SCORES, 1, 65)}
                    </div>

                    </div>

                  </div>
                  {/* GRID TWO - small info */}
                  <div className="grid grid-cols-4 gap-1">
                    {renderField("JURISDICTION", card.jurisdiction)}
                    {renderField("STATUS", card.status_description)}
                  </div>
                  {/* THIS THA BUTTON */}
                  <button
                    onClick={() => toggleExpandCard(index)}
                    className="mt-4 bg-gray-300 text-blue-900 px-4 py-1 hover:ring-2 hover:ring-blue-400 rounded hover:bg-white absolute bottom-4 right-4"
                  >
                    {expandedCard === index ? 'Back' : 'Details'}
                  </button>
                  {/* ADDITIONAL INFO SECTION */}
                  {expandedCard === index && (
                    <div className="mt-4 pt-4 border-t">
                    {/*  <h3 className="text-lg font-semibold mb-2">Additional Details</h3> */}
                    {renderField("SERIAL NUMBER", card.serial_number)}
                    {renderField("NICE CLASSES", card.international_codes)}

                      {/* Add more expanded details here if needed */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Navigation bar */}
      <div className="w-14 bg-blue-900 flex flex-col items-center py-4 space-y-9">
        <button
          onClick={() => sortCards('MARK_ID_TOP_SCORE', 'desc')}
          className="bg-white text-blue-800 p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Sort
        </button>
        <button
          onClick={goToTop}
          disabled={currentIndex === 0}
          className={`bg-white text-blue-800 p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          1st
        </button>
        <button
          onClick={prevCard}
          disabled={currentIndex === 0}
          className={`bg-gray-100 text-blue-800 p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          ↑
        </button>
        <button
          onClick={nextCard}
          disabled={currentIndex === sortedData.length - 1}
          className={`bg-gray-100 text-blue-800 p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${currentIndex === sortedData.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          ↓
        </button>
        <button
          onClick={goToBottom}
          disabled={currentIndex === sortedData.length - 1}
          className={`bg-gray-100 text-blue-800 p-2 rounded-full hover:bg-gray-200  focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${currentIndex === sortedData.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Lst
        </button>
      </div>
    </div>
  );
}
