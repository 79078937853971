import React, { useState, useEffect } from 'react';
import logo from './logo.ico';
import FileUpload from './components/FileUpload';
import RotatingCards from './components/RotatingCards';
import LoginModal from './components/LoginModal';

export default function App() {
  const [cardData, setCardData] = useState([]);
  const [isFileUploadVisible, setIsFileUploadVisible] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    // Check for existing session on component mount
    const storedSession = localStorage.getItem('session');
    if (storedSession) {
      const { username, expiresAt } = JSON.parse(storedSession);
      if (new Date().getTime() < expiresAt) {
        setIsAuthenticated(true);
        setUsername(username);
      } else {
        localStorage.removeItem('session');
        setIsLoginModalOpen(true);
      }
    } else {
      setIsLoginModalOpen(true);
    }
  }, []);

  const handleLoginSuccess = (token, username) => {
    setIsAuthenticated(true);
    setUsername(username);
    setIsLoginModalOpen(false);
    
    // Store session in localStorage
    const expiresAt = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
    localStorage.setItem('session', JSON.stringify({ username, expiresAt }));
  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    setIsAuthenticated(false);
    setUsername('');
    setIsLoginModalOpen(true);
  };

  const handleUploadSuccess = (data) => {
    console.log('Data received in App component:', data);
    try {
      const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
      setCardData(Array.isArray(parsedData) ? parsedData : [parsedData]);
    } catch (error) {
      console.error('Error parsing data:', error);
      setCardData(Array.isArray(data) ? data : [data]);
    }
  };

  const toggleFileUpload = () => {
    setIsFileUploadVisible(!isFileUploadVisible);
  };

  if (!isAuthenticated) {
    return (
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    );
  }

  return (
    <div className="flex flex-col h-screen">
      {/* Topbar Banner */}
      <div className="bg-blue-900 text-white p-4 text-left flex items-center justify-between">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-8 h-8 mr-2"/>
          <h1 className="text-2xl font-bold">TMSBM</h1>
        </div>
        <div className="flex items-center">
          <span className="mr-4">Welcome, {username}</span>
          <button
            onClick={toggleFileUpload}
            className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            {isFileUploadVisible ? 'Hide Upload' : 'Show Upload'}
          </button>
          <button
            onClick={handleLogout}
            className="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
          >
            Logout
          </button>
        </div>
      </div>
      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* FileUpload Component */}
        <div className={`${isFileUploadVisible ? 'w-1/3' : 'w-0'} transition-all duration-300 ease-in-out overflow-hidden bg-white`}>
          {isFileUploadVisible && <div className="p-4 overflow-auto h-full"><FileUpload onUploadSuccess={handleUploadSuccess} /></div>}
        </div>
        {/* RotatingCards Component */}
        <div className={`${isFileUploadVisible ? 'w-2/3' : 'w-full'} transition-all duration-300 ease-in-out overflow-hidden`}>
          {cardData.length > 0 ? (
            <RotatingCards data={cardData} />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500 text-xl">Upload files to view rotating cards</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
