import React, { useState, useRef } from 'react';
import Spinner from './Spinner'; // Assuming you have a Spinner component

const API_BASE_URL="/api"

export default function FileUpload({ onUploadSuccess }) {
    const [message, setMessage] = useState({ text: '', type: '' });
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false);
    const [progress, setProgress] = useState([]);
    const [processedData, setProcessedData] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState({
        optionOne: false,
        optionTwo: false,
        optionThree: false
    });
    const csvFileRef = useRef(null);
    const docxFileRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const csvFile = csvFileRef.current.files[0];
        const docxFile = docxFileRef.current.files[0];

        if (!csvFile || !docxFile) {
            setMessage({ text: 'Please select both CSV and DOCX files.', type: 'error' });
            return;
        }

        setIsProcessing(true);
        setIsProcessed(false);
        setMessage({ text: 'Uploading files...', type: 'info' });
        setProgress([]);

        const formData = new FormData();
        formData.append('csv_file', csvFile);
        formData.append('docx_file', docxFile);
        formData.append('settings', JSON.stringify(settings));

        try {
            const response = await fetch(`${API_BASE_URL}/upload-files/`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const sessionId = response.headers.get('X-Session-ID');
            if (!sessionId) {
                throw new Error('Session ID not received from server');
            }

            const eventSource = new EventSource(`${API_BASE_URL}/events/${sessionId}`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Received event:', data);

                    if (data.type === 'update') {
                        setProgress(prev => [...prev, data.message]);
                        setMessage({ text: data.message, type: 'info' });
                    } else if (data.type === 'result') {
                        setProgress(prev => [...prev, "Processing complete"]);
                        setMessage({ text: 'Processing complete. Click "View Results" to see the data.', type: 'success' });
                        setIsProcessing(false);
                        setIsProcessed(true);
                        setProcessedData(data.data);
                        console.log('Processed data:', data.data);
                        eventSource.close();
                    } else if (data.type === 'error') {
                        setMessage({ text: data.message, type: 'error' });
                        setIsProcessing(false);
                        eventSource.close();
                    }
                } catch (error) {
                    console.error('Error parsing event data:', error, 'Raw data:', event.data);
                    setMessage({ text: 'Error processing server response', type: 'error' });
                    setIsProcessing(false);
                    eventSource.close();
                }
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                setMessage({ text: 'Connection to server lost', type: 'error' });
                setIsProcessing(false);
                eventSource.close();
            };
        } catch (error) {
            console.error('Error:', error);
            setMessage({ text: `An error occurred: ${error.message}`, type: 'error' });
            setIsProcessing(false);
        }
    };

    const handleViewResults = () => {
        if (processedData) {
            console.log('Sending processed data to parent:', processedData);
            onUploadSuccess(processedData);
        } else {
            setMessage({ text: 'No processed data available', type: 'error' });
        }
    };

    const handleSettingChange = (setting) => {
        setSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
    };

    return (
        <div className="max-w-md mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-4">Document Upload</h1>
            <h2 className="text-l mb-3">This service requires the .csv and .docx from a TMTKO Knockout Report. Collision scores and complete details for the conflicting marks will be available after processing.</h2>
            <h2 className="text-l mb-8">It can take up to 2 minutes to complete a single processing request. Please be patient.</h2>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="csvFile" className="block text-sm font-medium text-gray-700 mb-1">
                        Upload CSV:
                    </label>
                    <input
                        type="file"
                        id="csvFile"
                        ref={csvFileRef}
                        accept=".csv"
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        onChange={() => setMessage({ text: '', type: '' })}
                    />
                </div>

                <div>
                    <label htmlFor="docxFile" className="block text-sm font-medium text-gray-700 mb-1">
                        Upload DOCX:
                    </label>
                    <input
                        type="file"
                        id="docxFile"
                        ref={docxFileRef}
                        accept=".docx"
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        onChange={() => setMessage({ text: '', type: '' })}
                    />
                </div>

                <div className="mt-4">
                    <button
                        type="button"
                        onClick={() => setShowSettings(!showSettings)}
                        className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                    >
                        <span>Advanced Settings</span>
                        <svg
                            className={`w-5 h-5 ${showSettings ? 'transform rotate-180' : ''}`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                    {showSettings && (
                        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                            <div className="space-y-2">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={settings.optionOne}
                                        onChange={() => handleSettingChange('optionOne')}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-gray-700">Option One</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={settings.optionTwo}
                                        onChange={() => handleSettingChange('optionTwo')}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-gray-700">Option Two</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={settings.optionThree}
                                        onChange={() => handleSettingChange('optionThree')}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-gray-700">Option Three</span>
                                </label>
                            </div>
                        </div>
                    )}
                </div>

                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <span className="flex items-center">
                            <Spinner size={20} color="#ffffff" />
                            <span className="ml-2">Processing...</span>
                        </span>
                    ) : (
                        'Upload and Process Files'
                    )}
                </button>
            </form>

            {isProcessed && (
                <button
                    onClick={handleViewResults}
                    className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    View Results
                </button>
            )}

            {message.text && (
                <div className={`mt-4 p-4 rounded ${
                    message.type === 'error' ? 'bg-red-100 text-red-700' :
                    message.type === 'success' ? 'bg-green-100 text-green-700' :
                    'bg-blue-100 text-blue-700'
                }`}>
                    {message.text}
                </div>
            )}

            {isProcessing && (
                <div className="mt-4">
                    <h2 className="text-lg font-semibold">Progress:</h2>
                    <ul className="list-disc pl-5">
                        {progress.map((step, index) => (
                            <li key={index}>{step}</li>
                        ))}
                    </ul>
                </div>
            )}
            <h2 className="text-l font-bold">MASSIMO - current notes:</h2>
            <h2 className="text-l font-semibold">- 'CR' stands for 'Collision Rating', and it's a number derived from our analytics which combines sentence similarity and traditional REGEX evaluations.</h2>
            <h2 className="text-l font-semibold">- There is no logic for design comparisions. That is possible, and a good premium, I think.</h2>
            <h2 className="text-l font-semibold">- Currently, we score by comparing the Prospective (or Applicant's) Mark against each conflicting mark according to the Mark Identifier (seemingly comprising the brand name, product name, slogan, etc.) and the NICE Classes.</h2>
            <h2 className="text-l font-semibold">- The settings affect the back end. We have plans for more buttons, that can change the sort, and remove entries based on certain metrics, like registration status, design inclusion, etc.</h2>

        </div>
    );
}
