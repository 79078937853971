import React from 'react';

const CircleRating = ({ value, size = 100 }) => {
  const strokeWidth = size * 0.1; // 10% of size for stroke width
  const radius = size / 2;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (value / 100) * circumference;

  const getColor = (value) => {
    if (value < 33) return 'text-green-500';
    if (value < 66) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="inline-flex items-center justify-center" style={{ width: size, height: size }}>
      <svg
        height={size}
        width={size}
        className={`transform -rotate-90 ${getColor(value)}`}
      >
        <circle
          stroke="currentColor"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <span className="absolute text-center font-bold" style={{ fontSize: `${size * 0.25}px` }}>{value}</span>
    </div>
  );
};

export default CircleRating;
